import logo from './logo.svg';
import './App.css';
import GoogleDrivePicker from './components/GoogleDrivePicker';

function App() {
  return (
    <div className="">
        <GoogleDrivePicker />
    </div>
  );
}

export default App;
